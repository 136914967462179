import { Grid, Pagination, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { getBlogCategories, getBlogPosts } from '../common/BlogApi';
import { BlogCategoriesResponse, BlogResponse, BlogStatus } from '../model/blog';
import BlogItem from './BlogItem';
import { forEach } from 'lodash';
import React from 'react';

export default function BlogGrid({ numPosts, paginationEnabled, blogStatus }: { numPosts: number, paginationEnabled: boolean, blogStatus: BlogStatus }) {

  let emptyBlogPosts: BlogResponse = { data: { posts: [], pagination: { total: 0, per_page: 0, current_page: 0, last_page: 0 } } };
  let emptyBlogCategories: BlogCategoriesResponse = { data: { categories: [] } };

  let [BlogCategories, setBlogCategories] = useState(emptyBlogCategories);
  let [blogPosts, setBlogPosts] = useState(emptyBlogPosts);
  let [loading, setLoading] = useState<boolean>(false);

  const [page, setPage] = React.useState(1);


  useEffect(() => {

    console.log('trygetBlogPosts');
    // if (!loading) {
    setLoading(true);
    getBlogCategories(setBlogCategories);
    getBlogPosts(setBlogPosts, numPosts, page, blogStatus);
    // }

  }, [loading, page, numPosts]);

  if (!blogPosts.data.posts || blogPosts.data.posts.length === 0) {
    return <></>;
  }

  var postsToShow = blogPosts.data.posts;
  //var postsToShow = blogPosts.data.posts.slice(0, numPosts);

  forEach(postsToShow, function (blogPost) {
    if (blogPost && blogPost.categories && blogPost.categories.length > 0) {
      var categorieId = blogPost.categories[0].id;
      var categorie = BlogCategories.data.categories.find((c) => c.id === categorieId);
      if (categorie) {
        blogPost.categoryImage = categorie.background_image;
      }
    }
  });




  return (
    <Grid container spacing={2} sx={{ justifyContent: "center", padding: "20px" }}>
      {postsToShow.map((blogPost, index) =>
        <Grid item key={"displayGrid" + index} sx={{ minWidth: '320px', maxWidth: '320px' }} >
          <BlogItem blogPost={blogPost} index={index} key={"display" + index} />
        </Grid>)
      }
      {paginationEnabled &&
        <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
          <Pagination count={blogPosts.data.pagination.last_page} defaultPage={blogPosts.data.pagination.current_page} onChange={(e, page) => setPage(page)} />
        </Grid>
      }
    </Grid>
  );
}



