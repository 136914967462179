import Paper from '@mui/material/Paper';
import '..//App.css';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import Bonus from './BonusDisplay';
import { Box } from '@mui/system';
import TopBanner from './TopBanner';
import { Bonuses } from '../common/Bonuses';
import Faq from './Faq';
import BannerColumn from './BannerColumn';
import About from './About';
import BlogGrid from './BlogGrid';
import { BlogStatus } from '../model/blog';

export default function Home() {
  return (
    <Box>      
      <TopBanner />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={9}>
            <Grid container spacing={3} id="coupons">

              {/* Blog */}
              <Grid item xs={12} id="faq">
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Dagens speltips</Typography>
                  <BlogGrid numPosts={3} paginationEnabled={false} blogStatus={BlogStatus.PUBLISHED}/>
                </Paper>
              </Grid>

              {/* About */}
              <Grid item xs={12} id="faq">
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <About />
                </Paper>
              </Grid>

              {/* Bonusar */}
              <Grid item xs={12} id="bonus">
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Bästa bonusarna just nu</Typography>
                  <div>
                    {Bonuses.map((bonus, index) => <Bonus bonus={bonus} index={index} key={"bonus" + index} />)}
                  </div>
                </Paper>
              </Grid>

              {/* Faq */}
              <Grid item xs={12} id="faq">
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Faq />
                </Paper>
              </Grid>

            </Grid>
          </Grid>

          {/* Banner column */}
          <Grid item>            
              <BannerColumn />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

}


