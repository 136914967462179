import { Container, Grid, Paper } from '@mui/material';
import BannerColumn from './BannerColumn';
import BlogGrid from './BlogGrid';
import { BlogStatus } from '../model/blog';

export default function BlogPage({ blogStatus }: { blogStatus: BlogStatus }) {

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
      <Grid container justifyContent="center" id="coupons">
        <Grid item xs={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            <BlogGrid numPosts={9} paginationEnabled={true} blogStatus={blogStatus} />
          </Paper>
        </Grid>
        <Grid item>          
            <BannerColumn />          
        </Grid>
      </Grid>
    </Container>
  );
}



