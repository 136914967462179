import { useTheme } from "@emotion/react";
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import '..//App.css';
import React from "react";
import Image from "../images/audience.jpg"
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export default function TopBanner(props) {

    const theme = useTheme();

    let text = theme.palette.primary.contrastText;

    return (
        <Grid container direction="column" justifyContent="center"
            sx={{ height: "300px", backgroundPosition: "0% 70%", backgroundSize: "cover", backgroundImage: `url(${Image})` }}>

            <Grid container justifyContent="center" >
                <Grid item maxWidth="sm" height="100%">
                    <Grid container direction="column" alignItems="center"
                        sx={{
                            p: 2,
                            backgroundColor: theme.palette.primary.main
                        }}
                    >
                        <Typography sx={{
                            color: text
                        }} variant="h5">Bästa verktygen för dina spel</Typography>

                        <List sx={{ color: text }}>
                            <ListItem disablePadding>
                                <Link className='nav-link' to="/#fixtures">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <TaskAltIcon sx={{ color: text }} />
                                        </ListItemIcon>
                                        <ListItemText sx={{ color: text }} primary="Oddsjämförelse för multiplar" />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            <ListItem disablePadding>
                                <HashLink className='nav-link' to="/#bonus">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <TaskAltIcon sx={{ color: text }} />
                                        </ListItemIcon>
                                        <ListItemText sx={{ color: text }} primary="Bonusar hos ledande operatörer" />
                                    </ListItemButton>
                                </HashLink>
                            </ListItem>
                            <ListItem disablePadding>
                                <HashLink className='nav-link' to="/#coupons">
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <TaskAltIcon sx={{ color: text }} />
                                        </ListItemIcon>
                                        <ListItemText sx={{ color: text }} primary="Liverättning" />
                                    </ListItemButton>
                                </HashLink>
                            </ListItem>
                        </List>


                    </Grid>
                </Grid>
            </Grid>

        </Grid>)
};

