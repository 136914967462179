import axios from 'axios';
import { BlogStatus } from '../model/blog';

const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5YWQxODQzOC1lMzAyLTRiMmYtYmNjZC03ZjNhZmM0MTU1ZDAiLCJqdGkiOiIzYjhlODRhNzIyYzkzNTY4OTkyNjMwYTUyYjUyM2JiY2NjNTE1ZmI3YzJiN2U4MzU5NTM2YjY2MGE0MGJkZmJmOWFkMTFjNzdkNTVlNDA1NiIsImlhdCI6MTcxOTQwMTQ5MS42ODIzMjYsIm5iZiI6MTcxOTQwMTQ5MS42ODIzMywiZXhwIjoyNTA4MzE5ODkxLjYxODY4Miwic3ViIjoiNzA0NTcwNTk2Mjc4Iiwic2NvcGVzIjpbXX0.jhivXi3Bwjfe_PFkK2jx-ozAz4LPo7eMcq53i4lXW8NcZUH31xTs-VBqh98CphDGpcJLXAt_lVq1t1OU27VJDK-2yPywmm6Hv_kOEd1kNRMA1eg7Fq1DE-UNuwyjLxa6EiFVa1dDhGu1tGudQrJ_ICETlqpuJbM-2WKrOc_J4-NBdjaqnn6TjlLFkluNqXofkEwUtnn4LjgAAa73kgtnQFZRx1Bn7eYQHEdFk2vY9VG4ExsQ-u-8kaiHSr39s3a1Gl1WKgrGsfOsg2EfiwhQgbqCHjGm_wuuuLXHmzkFo2lwxTIPA61uqDoo9oiPZhCK8-hYHD4Xyfkw408XNKUbLC2vqQagRfTXahGvfgZ1Tu1EOLnobJeMDvjh_UF_d77QghZPtLSodJjd2_QALXztp0f5nyuXH0VENL2h0kRDWMKWGqlCt8eUqEkW-2Ui2zXylLLanvZQ1VpNgq8nNKyCgkDL45hpHj-tTkLq7rataLFDY0Ai1X3BCuxb-st4mxrGOm1sfjGUwlqWGVaAUuibwdQOuDvvzyjc0FU43XEl-IJr-3c8S26wT0_mUCNgg6cvEcRHgjdJAet6QwV9eo44_UD9NnLrVuLV36y4OPXS_EumNgYivf7GY3iR3eBgDeTFVP8z1AD4oXWW2oIyFMSHkWoMA8bajJPQi0DvSwprZ1c';
const baseUrl = 'https://api.dropinblog.com/v2/blog/d79e08ae-0e6e-4ee8-bee4-7d03027c2829/'
export function getBlogPosts(setBlogPosts: any, numPosts: number, page: number, statuses: BlogStatus) {

    let url = baseUrl + 'posts?page=' + page + '&limit=' + numPosts + '&statuses=' + statuses;   
    console.log(url);

    var config = {
        method: 'GET',
        url: url,
        headers: {
            accept: 'application/json',
            authorization: token
        }
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setBlogPosts(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getBlogPost(setBlogPost: any, id: string) {

    console.log('getBlogPost ' + id);
    let url = baseUrl + 'posts/' + id;;

    var config = {
        method: 'GET',
        url: url,
        headers: {
            accept: 'application/json',
            authorization: token
        }
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setBlogPost(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getBlogPostBySlug(setBlogPost: any, slug: string) {

    let url = baseUrl + 'posts/slug/' + slug;
    console.log(url);

    var config = {
        method: 'GET',
        url: url,
        headers: {
            accept: 'application/json',
            authorization: token
        }
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setBlogPost(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getBlogCategories(setCategories: any) {

    console.log('getBlogCategories');
    let url = baseUrl + 'categories';

    var config = {
        method: 'GET',
        url: url,
        headers: {
            accept: 'application/json',
            authorization: token
        }
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setCategories(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}