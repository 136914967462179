import { Avatar, Paper, Grid, Typography, useMediaQuery, Box } from '@mui/material';
import React from 'react';
import { BlogPost } from '../model/blog';
import { useNavigate } from "react-router-dom";

import super13 from "../images/super13.jpg";

function BlogItem({ blogPost, index }: { blogPost: BlogPost, index: number, key: string }) {

    let styleBox = {
        maxWidth: "300px",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px"
    }

    let styleText = {
        padding: "20px"
    }

    const [expanded, setExpanded] = React.useState(false);

    const wide = useMediaQuery('(min-width:600px)');

    let navigate = useNavigate();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const regexp = /\[(\d+)\]/;

  let contentToShow = blogPost && blogPost.summary;
  contentToShow = contentToShow?.replace(regexp, '');

    return (

        <Paper elevation={3} sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.secondary.main
                    : theme.palette.grey[900]

            , minHeight: '350px', maxHeight: '350px', width: '300px', maxWidth: '300px', minWidth: '300px',
            ":hover": {
                outline: '3px solid #603F83FF', // Outline on hover
                cursor: "pointer"
            },
        }} onClick={() => navigate("/Blog/" + blogPost.slug)}>
            <Grid container sx={{ height: "350px" }} direction="column" alignItems="flex-start" justifyContent="space-between">
                
                <Box sx={{
                    backgroundImage: `url(${blogPost.categoryImage?blogPost.categoryImage:super13})`,
                    minHeight: "120px",
                    width: "100%",
                    backgroundSize:"cover"
                }} />

                <Grid item flexGrow={1} flexShrink={1} sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "wrap", paddingTop: "10px", paddingRight: "20px", paddingLeft: "20px", maxHeight: "135px"
                }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 800 }}>
                        {blogPost.title}
                    </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                        {contentToShow}
                    </Typography>
                </Grid>
                <Grid item sx={{ padding: "10px 20px 20px 20px" }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item sx={{ pt: "5px", pb: "10px", pl: "0px", pr: "10px" }}>
                            <Avatar alt={blogPost.author.name} src={blogPost.author.photo} sx={{ width: 35, height: 35 }} />
                        </Grid>
                        <Grid item >
                            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0} >
                                <Grid item >
                                    <Typography variant="caption">
                                        {blogPost.author.name}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ verticalAlign: "0" }} justifyContent="flex-start">
                                    <Typography variant="caption">
                                        {"Publicerad " + blogPost.publishedAt.toString().substring(0, 10)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    );

}

export default BlogItem;
