import axios from 'axios';
import { addCouponToCookie, removeCouponFromCookie } from './CookieHandling';

//const apiUrl = 'https://betslip.azildevelopment.com/';
const apiUrl = 'https://lobster-app-oq3lw.ondigitalocean.app/';
//const apiUrl = 'https://localhost:7028/';

export function addCouponToApi(coupon, cookies, setCookie) {

    var config = {
        method: 'post',
        url: apiUrl + 'AddCoupon',
        headers: {
            'Content-Type': 'application/json'
        },
        data: coupon
    };

    axios(config)
        .then(function (response) {
            const id = response.data;
            if (!(coupon.Coupon.GoogleId.length > 0)) {
                addCouponToCookie(id, cookies, setCookie);
            }
        })
        .catch(function (error) {
            console.log(error);
        });

}

export function deleteCouponFromApi(id, cookies, setCookie) {

    var config = {
        method: 'post',
        url: apiUrl + 'DeleteCoupon',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            CouponId: id
        }
    };

    axios(config)
        .then(function (response) {
            removeCouponFromCookie(id, cookies, setCookie);
        })
        .catch(function (error) {
            console.log(error);
        });

}

export function deletePoolXCouponFromApi(id, setAddedCouponId) {

    var config = {
        method: 'post',
        url: apiUrl + 'DeletePoolXCoupon',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            CouponId: id
        }
    };

    axios(config)
        .then(function (response) {
            setAddedCouponId('remove' + id);
        })
        .catch(function (error) {
            console.log(error);
        });

}

export function getCoupons(setCoupons, couponIds, setFetching, googleId) {

    setFetching(true);

    var req = {
        CouponIds: couponIds,
        GoogleId: googleId
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetCoupons',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setCoupons(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getPoolXCoupons(setCoupons, googleId) {

    var req = {        
        GoogleId: googleId
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetPoolXCouponsForUser',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setCoupons(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getPoolXCoupon(setCoupon, setFetching, id) {

    setFetching(true);

    var req = {        
        CouponId: id
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetPoolXCoupon',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setCoupon(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function addPoolXCoupon(file, googleId, setCouponId) {

    const formData = new FormData();
    formData.append('file', file, "coupon.txt");
    formData.append('GoogleId', googleId);

    var config = {
        method: 'post',
        url: apiUrl + 'AddPoolXCoupon',
        headers: {
            'Content-Type': 'application/json'
        },
        data: formData
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setCouponId(data);
            return response;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getCouponReport(setCouponReport, googleId, reportType) {

    let reportTypeNum = 0;

    if (reportType === "1") {
        reportTypeNum = 1;
    }

    var req = {
        GoogleId: googleId,
        ReportType: reportTypeNum
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetCouponReport',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setCouponReport(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getFixtures(setFixtures, countryId, leagueId, fixtureId) {

    var req = {
        CountryId: countryId,
        LeagueId: leagueId,
        FixtureId: fixtureId
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetFixtures',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setFixtures(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getCountries(setCountries) {

    var req = {
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetCountries',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setCountries(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getLeagues(setLeagues) {

    var req = {
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetLeagues',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setLeagues(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getSelections(setSelections, setInputValue) {

    var req = {
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetSelections',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setSelections(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function loginUser(profile, refreshCoupons) {

    var req = {
        Profile: profile,
    };

    var config = {
        method: 'post',
        url: apiUrl + 'LoginUser',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            if (!data.isSuccess) {

                //logout

            }
            refreshCoupons(profile);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getOddsForMultiple(setMultipleMarketOdds, fixtureSelections) {

    var req = {
        FixtureSelections: fixtureSelections
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetOddsForMultiple',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setMultipleMarketOdds(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getOddsForFixture(setFixtureOdds, fixtureId) {

    var req = {
        FixtureIds: [fixtureId]
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetOddsForFixtures',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setFixtureOdds(Object.values(data)[0]);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getPoolXRounds(setPoolXRounds) {

    var req = {
    };

    var config = {
        method: 'post',
        url: apiUrl + 'GetPoolXRounds',
        headers: {
            'Content-Type': 'application/json'
        },
        data: req
    };

    axios(config)
        .then(function (response) {
            const data = response.data;
            setPoolXRounds(data);
        })
        .catch(function (error) {
            console.log(error);
        });
}
