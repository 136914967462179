import { AppBar, Avatar, Box, Container, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import Logo from "../images/logo-no-background.png"
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { AccountCircle, Logout } from "@mui/icons-material";
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import BoltIcon from '@mui/icons-material/Bolt';
import BlogIcon from '@mui/icons-material/DescriptionOutlined';
import { useState } from "react";


export default function TopMenu(props) {
    let navigate = useNavigate();
    const wide = useMediaQuery('(min-width:900px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(props.profile);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlelogOut = () => {
        setAnchorEl(null);
        googleLogout();
        props.logOut();
    };

    const handleSuccess = (resp) => {
        setAnchorEl(null);
        props.onSuccess(resp);
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}>
            {props.profile && props.profile.Name ? (
                <div>
                    <MenuItem onClick={handlelogOut}>
                        <Logout fontSize="small" />
                        Logout
                    </MenuItem>
                </div>
            ) : (
                <div>
                    <MenuItem onClick={handleClose}>
                        <GoogleLogin
                            clientId={props.clientId}
                            onSuccess={(resp) => handleSuccess(resp)}
                            onError={props.onFailure}
                        />
                    </MenuItem>
                </div>)
            }
        </Menu>
    );

    return (

        <AppBar >
            <Container maxWidth="xl">
                <Toolbar className='toolbar'>
                    <Grid container direction="row" justifyContent="center">
                        <IconButton color="inherit" onClick={() => navigate("/")}>
                            <img width={wide ? "300px" : "200px"} src={Logo} alt="Logo" />
                        </IconButton>
                        <Grid item >
                            <Grid container justifyContent="center">

                                <MenuItem onClick={() => navigate("/Blog")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <BlogIcon />
                                    </ListItemIcon>
                                    <ListItemText>Speltips</ListItemText>
                                </MenuItem>

                                <MenuItem onClick={() => navigate("/Odds")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <BarChartIcon />
                                    </ListItemIcon>
                                    <ListItemText>Oddsjämförelse</ListItemText>
                                </MenuItem>


                                <MenuItem onClick={() => navigate("/Pools")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <BarChartIcon />
                                    </ListItemIcon>
                                    <ListItemText>Tips</ListItemText>
                                </MenuItem>


                                {/*   <MenuItem onClick={() => navigate("/Syndicates")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText>Andelsspel</ListItemText>
                                </MenuItem> */}


                                {/*  <MenuItem onClick={() => navigate("/PoolX")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <GroupsIcon />
                                    </ListItemIcon>
                                    <ListItemText>PoolX</ListItemText>
                                </MenuItem>
 */}

                                <MenuItem onClick={() => navigate("/Live")} sx={{ fontSize: "14px" }}>
                                    <ListItemIcon sx={{ color: "inherit" }}>
                                        <BoltIcon />
                                    </ListItemIcon>
                                    <ListItemText>Liverättning</ListItemText>
                                </MenuItem>



                                {
                                    props.profile && props.Name ?
                                        <MenuItem onClick={() => navigate("/Report")} sx={{ fontSize: "14px" }}>
                                            <ListItemIcon sx={{ color: "inherit" }}>
                                                <StackedLineChartIcon />
                                            </ListItemIcon>
                                            <ListItemText>Avgjorda spel</ListItemText>
                                        </MenuItem> : <></>
                                }

                                <IconButton
                                    onClick={handleClick}
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-haspopup="true"
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    color="inherit"
                                >
                                    {
                                        props.profile && props.profile.Name ?
                                            (
                                                <div>
                                                    <Avatar alt={props.profile.Name} src={props.profile.ImageUrl} />
                                                </div>
                                            ) :
                                            (
                                                <AccountCircle sx={{ fontSize: 40 }} />
                                            )
                                    }
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
            {renderMenu}
        </AppBar>



    );
}