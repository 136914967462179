import { Container, Grid, Paper, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import BannerColumn from './BannerColumn';
import React, { useEffect, useState } from 'react';

import { getBlogPostBySlug } from '../common/BlogApi';
import { BlogPostResponse } from '../model/blog';
import { useParams } from 'react-router-dom';
import BlogAuthor from './BlogAuthor';
import { getFixtures, getOddsForFixture } from '../common/BetslipApi';
import OddsTable from './OddsTable';
import { Fixture } from '../model/fixture';

export default function BlogDetail() {

  let [blogPost, setBlogPost] = useState<BlogPostResponse | undefined>(undefined);
  let [loading, setLoading] = useState<boolean>(false);
  let [fixtureOdds, setFixtureOdds] = React.useState([]);
  let [fixtureId, setFixtureId] = React.useState<number | undefined>(undefined);
  const [fixtures, setFixtures] = React.useState<Array<Fixture>>([]);

  const routeParams = useParams();
  let blogPostId: string = "";

  if (routeParams.blogPostId)
    blogPostId = routeParams.blogPostId;

  useEffect(() => {
    console.log('try getBlogPosts');
    if (!loading) {
      setLoading(true);
      getBlogPostBySlug(setBlogPost, blogPostId);
      //getBlogPost(setBlogPost, blogPostId);
    }
    if (fixtureId !== undefined) {
      getOddsForFixture(setFixtureOdds, fixtureId)
      getFixtures(setFixtures, undefined, undefined, fixtureId);
    }

  }, [loading, blogPostId, fixtureId, fixtures.length]);

  const wide = useMediaQuery('(min-width:600px)');

  let stylePaper: SxProps<Theme> = {
    padding: "10px",
    margin: "30px",
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.secondary.main
        : theme.palette.grey[900]
  };

  const regexp = /\[(\d+)\]/;

  let contentToShow = blogPost && blogPost.data.post.content;

  const match = blogPost && blogPost.data.post.content.match(regexp);

  if (match && match[1]) {
    const numberInsideBrackets = match[1];

    if (fixtureId === undefined) {
      setFixtureId(parseInt(numberInsideBrackets));
    }
  }

  contentToShow = contentToShow?.replace(regexp, '');

  let fixture = fixtures.filter(f => f.id === fixtureId);

  return (

    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      <Grid container id="coupons" direction={wide ? "row" : "column"}>
        <Grid item xs={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            <Grid container  >
              {blogPost && blogPost.success && blogPost.data && blogPost.data.post && contentToShow ?
                <Grid container direction="column" >
                  <Grid item>
                    <Typography variant='h4'>{blogPost.data.post.title}</Typography>
                  </Grid>
                  <Grid item>
                    <div dangerouslySetInnerHTML={{ __html: contentToShow }} />
                  </Grid>
                  {fixture.length === 1 &&
                    <Paper sx={stylePaper}>
                      <Grid container justifyContent="center">
                        <Typography variant='h5'>{fixture[0].name}</Typography>
                        <OddsTable odds={fixtureOdds} />
                      </Grid>
                    </Paper>}
                  <Grid item>
                    <BlogAuthor name={blogPost.data.post.author.name} bio={blogPost.data.post.author.bio} photo={blogPost.data.post.author.photo} />
                  </Grid>
                </Grid>
                :
                <Typography variant='h4'>Loading...</Typography>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <BannerColumn />
        </Grid>
      </Grid>

    </Container>
  );
}



