import { Avatar, Container, Grid, Paper, Typography, useMediaQuery } from '@mui/material';


export default function BlogPage( {name, bio, photo}: {name: string, bio: string, photo: string}) {


  const wide = useMediaQuery('(min-width:600px)');

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>

      {!wide ? <><br></br><br></br><br></br></> : <></>}


          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            <Grid container>
            <Avatar alt={name} src={photo} />
            <Typography variant="h5">{name}</Typography> 
            <div dangerouslySetInnerHTML={{ __html: bio }} />
            </Grid>
          </Paper>
    </Container>
  );
}



